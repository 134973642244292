.login-container {
  display: flex;

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    & > * {
      margin: 10px 0;
    }
  }

  & > * {
    margin: 0 25px;
  }
}
